<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
        <Thankyou />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import Thankyou from "../components/thank/Thankyou.vue";

export default {
  name: "Thank",
  data: function() {
    return {};
  },
  methods: {},
  mounted() {},
  watch: {},
  components: {
    Navbar,
    LeftMenu,
    Thankyou,
  },
};
</script>
