<template>
  <div class="thanks-wrapper">
    <div class="img-top">
      <img
        src="https://whoifollow.s3.us-west-2.amazonaws.com/nda/light-mode-logo.png"
        alt="logo"
      />
    </div>
    <h3>Thank You</h3>
    <div class="card-two">
      <p>© 2017-2022 All Rights Reserved</p>
      <p>77 W Wacker Drive, Suite 4500, Chicago, IL 60601</p>
      <p>(877)USAGLOBALNDA</p>
      <a href="#">www.globalnda.crushcontracts.com</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Thankyou",
  data() {
    return {
      event: "",
      folderId: "",
    };
  },
 
  methods: {

    //get URL parameters
    getLinkUrl() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.folderId = urlParams.get("folderId");
      this.event = urlParams.get("event");
      this.UpdateStatus();
    },
    // api to update backend and stor foxit data
    UpdateStatus: function () {
      var formData = new FormData();
      formData.append("folderId", this.folderId);
      formData.append("event", this.event);
      axios
        .post(process.env.VUE_APP_API_URL + "enterprise/agreement/create", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.getUserData();
            setTimeout(() => {
              this.$router.push({ name: "EnterpriseHome"});
            }, 15000);
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },
  mounted() {
    this.getLinkUrl(1);
  },
};
</script>

<style>
.thanks-wrapper {
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-top {
  max-width: 300px;
  margin: 15px auto 0;
}

.img-top img {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.card-two {
  padding: 15px;
  color: #59585b;
  text-align: center;
}

.card-two a {
  color: #58595b;
}
</style>
